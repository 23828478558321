import React from "react";

const FindusBanner = () => {
    return (
        <div className="findus-title">
            <div className="container">
                <h1 className="findus-title__title">
                    Solution to your AI challenges,
                    <span>with Subject Matter Experts <span className="findus-title__title_highlight">
                          in-the-loop
                    </span></span>

                </h1>
                <p className="findus-title__description">To find the best SMEs, please tell us what industry expertise you are looking for and the use-cases you would like to discuss with the expert.</p>
            </div>
        </div>
    );
};

export default FindusBanner;
