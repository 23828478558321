import React from "react";
import { Link } from "gatsby";

import Next from "../../images/next.png";
import logoBlk from "../../images/aisme_labs_logo.svg";
import imageAvatar from "../../images/img_avatar.png";
import tabImage from "../../images/tab-image.png";

const FindUsCard = () => {
    return (
        <div className="joinus-card findus-card mt-5 mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12">
                        <div className="joinus-card__container ">
                            <h3 className="joinus-card__title">Request a free assessment</h3>
                            <form className="joinus-card__form" method="post" action="#">

                                <div className="joinus-card__form_nameWrapper">
                                    <input type="text" placeholder="First Name*" name="name" id="name" />
                                    <input type="text" placeholder="Last Name*" name="name" id="name" />

                                </div><div className="joinus-card__form_nameWrapper">
                                    <input type="text" placeholder="Job Title*" name="job" id="job" />
                                    <input type="email" placeholder="Business Email*" name="bemail" id="bemail" />

                                </div>
                                <div className="joinus-card__form_nameWrapper">
                                    <input type="number" placeholder="Phone*" name="phone" id="phone" />
                                    <input type="Link" placeholder="Website*" name="website" id="website" />

                                </div>
                                <div className="joinus-card__form_nameWrapper">
                                    <input type="text" placeholder="Organization*" name="organization" id="organization" />
                                    <input type="text" placeholder="Primary Industry*" name="industry" id="industry" />

                                </div>
                                <input type="Link" name="linkedIn" placeholder="LinkedIn Url*" id="linkedIn" />
                                <textarea type="text" name="projecttDesc" placeholder="In few words describe your project/use-case." id="projecttDesc" />
                                <p className="findus-card__form_text">By submitting your information to our website you agree to the terms outlined in our Privacy Policy.</p>
                                <button className="joinus-card__form_submit" type="submit">Join as an AI-SME</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-1 col-sm-12 m-auto">
                        <img src={Next}  className="joinus-card__next" alt="" />

                    </div>
                    <div className="col-lg-5 col-sm-12">

                        <div className="joinus-card__container ">
                            <div className="joinus-card__imgWrapper findus-card__contentWrapper">
                                <img src={imageAvatar} className="findus-card__img" alt="logo" />
                                <p className="findus-card__content">Technologist with 20+ years of experience in Artificial Intelligence and financial sector ...</p>
                            </div>
                            <div className="joinus-card__imgWrapper findus-card__contentWrapper">
                                <img src={imageAvatar} className="findus-card__img" alt="logo" />
                                <p className="findus-card__content">Technologist with 20+ years of experience in Artificial Intelligence and financial sector ...</p>
                            </div>
                            
                            <div className="joinus-card__imgWrapper findus-card__contentWrapper">
                                <img src={imageAvatar} className="findus-card__img" alt="logo" />
                                <p className="findus-card__content">Technologist with 20+ years of experience in Artificial Intelligence and financial sector ...</p>
                            </div>
                            
                            <div className="joinus-card__imgWrapper findus-card__contentWrapper">
                                <img src={imageAvatar} className="findus-card__img" alt="logo" />
                                <p className="findus-card__content">Technologist with 20+ years of experience in Artificial Intelligence and financial sector ...</p>
                            </div>
                            
                            <button className="findus-card__button" type="submit">Select an AISME</button>



                        </div>
                    </div>

                </div>
            </div>

        </div>

    );
};

export default FindUsCard;
