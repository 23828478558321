import React from "react";

const WorksSection = () => {
    return (
        <section className="worksection findus__worksection">
            <div className="container">
                <h2 className="worksection__title">How does AI-SME work?</h2>
                <div className="row">
                    <div className=" col-md-4 col-sm-12 mb-3">
                        <div className="worksection__card">
                            <h3 className="worksection__card-title">1</h3>
                            <div className="worksection__card-contents">
                                <h5 className="worksection__card-contentsTitle"> Assessment</h5>
                                <p className="worksection__card-desc" >Submit your request for assessment and get matched with SMEs</p>

                            </div>

                        </div>
                    </div>
                    <div className=" col-md-4 col-sm-12 mb-3">
                        <div className="worksection__card">

                            <h3 className="worksection__card-title">2</h3>
                            <div className="worksection__card-contents">
                                <h5 className="worksection__card-contentsTitle">Select</h5>

                                <p className="worksection__card-desc">From a curated list, select pre-vetted and qualified AI Subject Matter Experts </p>

                            </div>

                        </div>
                    </div> <div className=" col-md-4 col-sm-12  mb-3">
                        <div className="worksection__card">

                            <h3 className="worksection__card-title">3</h3>
                            <div className="worksection__card-contents">
                                <h5 className="worksection__card-contentsTitle">Engage</h5>

                                <p className="worksection__card-desc">Engage with selected experts in-the-loop for your AI endeavor </p>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </section >


    );
};

export default WorksSection;
