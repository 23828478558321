import * as React from "react";

import FindusBanner from "../components/findus-banner";
import FindUsCard from "../components/findus-cards/findus-cards";
import FindusQuestions from "../components/findus-questions";
import FindusWorks from "../components/findus-works ";
import Layout from "../components/layout";
import { SEO } from "../components/seo";

export const Head = () => <SEO />;

const FindUs = () => {
  return (
    <Layout headerSolid activeTab={2}>
      <FindusBanner />
      <FindusWorks />
      <FindUsCard />
      <FindusQuestions />
    </Layout>
  );
};

export default FindUs;
